const withdrawService = http => {
  const endpoint = `${process.env.VUE_APP_API_BASE_URL}finance/withdraw`
  const endpointAdmin = `${process.env.VUE_APP_API_BASE_URL}finance/admin/withdraw`

  const get = urlComplementar => {
    // eslint-disable-next-line no-param-reassign
    urlComplementar = urlComplementar === undefined ? '' : urlComplementar
    const url = `${endpoint}/my-withdraw?${urlComplementar}`
    return http.get(url).then(data => data.data)
  }

  const getAdmin = idStatus => {
    // eslint-disable-next-line no-param-reassign
    idStatus = idStatus === undefined ? '' : idStatus
    const url = `${endpointAdmin}/list-withdraw/${idStatus}`
    return http.get(url).then(data => data.data)
  }

  const post = data => http.post(`${endpoint}/store-withdraw`, data)
  const postAdmin = data => http.post(`${endpointAdmin}/approve-withdraw`, data)

  /**
   * Funcao responsavel deletar dado especifico
   * @param {Int} id
   */
  const destroy = id => {
    const url = `${endpoint}/client-cancel-withdraw/${id}`
    return http.delete(url)
  }

  return {
    get,
    getAdmin,
    post,
    postAdmin,
    destroy,
  }
}

export {
  // eslint-disable-next-line import/prefer-default-export
  withdrawService,
}
