<template>
  <b-card :title="`${$t('withdraws.title')}`">
    <b-card-text>
      <!-- search input -->
      <div class="custom-search d-flex justify-content-between mb-1">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.modalAdd
          variant="primary"
        >
          <feather-icon
            icon="PlusSquareIcon"
            class="mr-50"
          />
          <span>{{ $t('withdraws.request_withdraw') }}</span>
        </b-button>
        <b-form-group>
          <div class="d-flex align-items-center">
            <b-form-input
              v-model="searchTerm"
              :placeholder="`${$t('withdraws.field_search')}`"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="info"
              class="mr-1"
              size="sm"
              @click="editarItem(props.row.originalIndex)"
            >
              <feather-icon
                icon="Edit2Icon"
                class="mr-50"
              />
              <span>{{ $t('withdraws.actions.view') }}</span>
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="danger"
              size="sm"
              :disabled="props.row.status === 4"
              @click="deleteItem(props.row.uuid)"
            >
              <feather-icon
                icon="TrashIcon"
                class="mr-50"
              />
              <span>{{ $t('withdraws.actions.cancel') }}</span>
            </b-button>
          </span>

          <span v-else-if="props.column.field === 'type'">
            {{ typeVariantLabel(1) }}
          </span>

          <span v-else-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ statusVariantLabel(props.row.status) }}
            </b-badge>
          </span>

          <span v-else-if="props.column.field === 'created_at'">
            {{ props.row.created_at | moment('DD/MM/YYYY - H:mm:ss') }}
          </span>

          <span v-else-if="props.column.field === 'value'">
            {{ formatPrice(props.row.value) }}
          </span>

          <span v-else-if="props.column.field === 'value_approve'">
            {{ formatPrice(props.row.value_approve) }}
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </b-card-text>

    <b-modal
      id="modalEdit"
      centered
      :title="`${$t('withdraws.modal_info.title')}`"
      hide-footer
    >
      <b-card-text v-if="renderModal">
        <b-badge variant="dark">
          {{ editItem.created_at | moment('DD/MM/YYYY - H:mm:ss') }}
        </b-badge>
        <b-badge
          variant="warning"
          class="mx-1"
        >
          {{ editItem.user_ip }}
        </b-badge>
        <b-badge :variant="statusVariant(editItem.status)">
          {{ statusVariantLabel(editItem.status) }}
        </b-badge><br><br>
        <p>
          <strong>{{ $t('withdraws.modal_info.type') }}:</strong>{{ typeVariantLabel(editItem.coin_id === null ? '2' : '1') }}<br>
          <strong>{{ $t('withdraws.modal_info.requested_value') }}:</strong> {{ editItem.value }}<br>
          <strong>{{ $t('withdraws.modal_info.approved_value') }}:</strong> {{ editItem.value_approve }}<br>
          <strong>{{ $t('withdraws.modal_info.notes') }}:</strong><br>
          {{ editItem.note }}
        </p>
      </b-card-text>
    </b-modal>

    <b-modal
      id="modalAdd"
      centered
      :title="`${$t('withdraws.form.title')}`"
      hide-footer
    >
      <b-card-text>
        <validation-observer ref="addValidation">
          <b-form @submit.prevent>
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="value"
                rules="required"
              >
                <label>{{ $t('withdraws.form.value') }}: *</label>
                <b-form-input
                  v-model.lazy="newItem.value"
                  v-money="money"
                  name="value"
                  type="text"
                  :state="errors.length > 0 ? false : null"
                  placeholder="Valor"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <label
                for="mensagem"
              >{{ $t('withdraws.form.notes') }}</label>
              <validation-provider
                #default="{ errors }"
                name="mensagem"
                rules="min:10"
              >
                <b-form-textarea
                  id="note"
                  v-model="newItem.note"
                  name="mensagem"
                  :placeholder="`${$t('withdraws.form.notes')}...`"
                  rows="3"
                  max-rows="6"
                  maxlength="255"
                  min="10"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <div class="text-center">
              <b-button
                variant="primary"
                size="lg"
                type="submit"
                @click="validationFormNew"
              >
                {{ $t('withdraws.form.button_submit') }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BCard,
  BCardText,
  BButton,
  BModal,
  BForm,
  BFormTextarea,
  BBadge,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import 'vue-good-table/dist/vue-good-table.css'
import { withdrawService } from '@/services/withdraw'
import { userService } from '@/services/user/list'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import { VMoney } from 'v-money'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    VueGoodTable,
    BCard,
    BCardText,
    BFormGroup,
    BFormInput,
    BButton,
    BModal,
    BForm,
    BFormTextarea,
    BBadge,
  },
  directives: {
    Ripple,
    money: VMoney,
  },
  data() {
    return {
      required,
      userService: userService(this.$http),
      withdrawService: withdrawService(this.$http),
      dir: false,
      renderModal: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false, /* doesn't work with directive */
      },
      rows: [],
      searchTerm: '',
      editItem: {},
      newItem: {
        fiat_coin: 'usd',
      },
    }
  },
  computed: {
    columns() {
      return [
        {
          label: this.$t('withdraws.table_header.identifier'),
          field: 'id',
        },
        {
          label: this.$t('withdraws.table_header.type'),
          field: 'type',
        },
        {
          label: this.$t('withdraws.table_header.requested_value'),
          field: 'value',
          sortable: false,
        },
        {
          label: this.$t('withdraws.table_header.approved_value'),
          field: 'value_approve',
          sortable: false,
        },
        {
          label: this.$t('withdraws.table_header.status'),
          field: 'status',
        },
        {
          label: this.$t('withdraws.table_header.time'),
          field: 'created_at',
        },
        {
          label: this.$t('withdraws.table_header.actions'),
          field: 'action',
          sortable: false,
        },
      ]
    },
    statusVariantLabel() {
      const statusLabel = {
        /* eslint-disable key-spacing */
        0: this.$t('withdraws.withdraw_status.denied_admin'),
        1: this.$t('withdraws.withdraw_status.approved'),
        2: this.$t('withdraws.withdraw_status.pending'),
        3: this.$t('withdraws.withdraw_status.email_pending'),
        4: this.$t('withdraws.withdraw_status.canceled_user'),
        5: this.$t('withdraws.withdraw_status.canceled_denied'),
        /* eslint-enable key-spacing */
      }

      return status => statusLabel[status]
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        0: 'light-danger',
        1: 'light-success',
        2: 'light-info',
        3: 'light-primary',
        4: 'light-warning',
        5: 'light-secondary',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    typeVariantLabel() {
      const statusLabel = {
        /* eslint-disable key-spacing */
        1: 'USD',
        2: 'FIAT',
        /* eslint-enable key-spacing */
      }

      return status => statusLabel[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  async created() {
    this.listItens()
  },
  methods: {
    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    async listItens() {
      const resultado = await this.withdrawService.get()
      this.rows = resultado.data.withdraw
    },
    deleteItem(id) {
      this.$swal({
        title: 'ATENÇÃO!',
        text: 'Você deseja realmente cancelar sua solicitação?',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.withdrawService.destroy(id)
          this.$swal({
            icon: 'success',
            title: 'Deletado!',
            text: 'Seu saque foi cancelado.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.listItens()
        }
      })
    },
    async editarItem(id) {
      this.editItem = await this.rows[id]
      this.renderModal = await true
      this.$bvModal.show('modalEdit')
    },
    validationFormNew() {
      // eslint-disable-next-line consistent-return
      this.$refs.addValidation.validate().then(async success => {
        if (success) {
          const withdrawFinalValue = this.newItem.value
            .replace(/\.*/g, '')
            .replace(',', '.')
          const data = await {
            value: withdrawFinalValue,
            note: this.newItem.note,
            fiat_coin: this.newItem.fiat_coin,
          }

          try {
            await this.withdrawService.post(data)

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Pedido de saque enviado com sucesso',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })

            this.$bvModal.hide('modalAdd')
            this.listItens()
            this.newItem = {}
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.data.Message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      })
    },
  },
}
</script>
